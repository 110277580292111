/*------------------------------------*\
RESET
\*------------------------------------*/
/* http://meyerweb.com/eric/tools/css/reset/ 
v2.0b1 | 201101 
NOTE:WORK IN PROGRESS
USE WITH CAUTION AND TEST WITH ABANDON */

html,body,div,span,applet,object,iframe,
h1,h2,h3,h4,h5,h6,p,blockquote,pre,
a,abbr,acronym,address,big,cite,code,
del,dfn,em,img,ins,kbd,q,s,samp,
small,strike,strong,sub,sup,tt,var,
b,u,i,center,
dl,dt,dd,ol,ul,li,
fieldset,form,label,legend,
table,caption,tbody,tfoot,thead,tr,th,td,
article,aside,canvas,details,figcaption,figure,
footer,header,hgroup,menu,nav,section,summary,
time,mark,audio,video{
  margin:0;
  padding:0;
  border:0;
  outline:0;
  font-size:100%;
  font:inherit;
  vertical-align:baseline;
}
/* HTML5 display-role reset for older browsers */
article,aside,details,figcaption,figure,
footer,header,hgroup,menu,nav,section{
  display:block;
}
body{
  line-height:1;
}
ol,ul{
  list-style:none;
}
blockquote,q{
  quotes:none;
}
blockquote:before,blockquote:after,
q:before,q:after{
  content:’’;
  content:none;
}
ins{
  text-decoration:none;
}
del{
  text-decoration:line-through;
}

table{
  border-collapse:collapse;
  border-spacing:0;
}

/* Your Code Goes Here */

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
}

body {
  font-size: 1.6rem;
  background-color: #FFFFFF;
  color: #222222;
  font-family: Roboto,Arial,sans-serif;
  font-size: 1.6rem;
  line-height: 2.4rem;
  margin: 0 auto;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}


.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  height: 100vh;
  /* zoom: reset; */
}

.nav-main {
  position: fixed;
  top: 0;
  left:0;
  width: calc(100% - 4rem);
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 2rem;
  background-color:  #272727;
  border-radius: 4rem;
  border: none;
}

.nav-header {
  font-size: 3rem;
  letter-spacing: 0.025rem;
  line-height: 2.8rem;
  font-weight: bold;
  text-align: left;
  margin: 2rem;
  color: #fbffff;
}

.nav-links-container {
  height: 7rem;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.nav-links {
  cursor: pointer;
  margin-right: 1.25rem;
  color: #fbffff;
  font-size: 4rem;
  margin-right: 2rem;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

@media (max-width: 500px) {
  .nav-header {
    font-size: 2rem;
  }
  .nav-links {
    font-size: 3rem;
  }
}

@media (max-width: 400px) {
  .nav-header {
    font-size: 1.8rem;
  }
  .nav-links {
    font-size: 2rem;
  }
}

.nav-links:hover {
  color: red;
}

.notes-list-wrapper {
  width: 100%;
  height: 100%;
  padding: 2.5rem 0rem;
  padding-top:11.7rem;
  background-color:  #121212;
}

.notes-list-wrapper h2 {
  font-size: 2rem;
  font-weight: bold;
  text-align: left;
  margin-bottom: 3rem;
  color:#fbffff;
  margin-left: 2.5rem;
}

.searchContainer {
  margin-bottom: 0rem;
}

.searchContainer input {
  border-radius: 0.5rem;
  border: none;
  width: 15%;
  text-align: center;
  background-color: #272727;
  color: white;
  font-size: 1.6rem;
  padding: 0.5rem;
}
.searchContainer input::-webkit-input-placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: white;
  opacity: 1; /* Firefox */
}
.searchContainer input::-ms-input-placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: white;
  opacity: 1; /* Firefox */
}
.searchContainer input::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: white;
  opacity: 1; /* Firefox */
}
.searchContainer input:-ms-input-placeholder { /* Internet Explorer 10-11 */
  color: white;
}
.searchContainer input::-ms-input-placeholder { /* Microsoft Edge */
  color: white;
}
@media (max-width: 1200px) {
  .searchContainer input {
    width: 25%;
  }
}
@media (max-width: 800px) {
  .searchContainer input {
    width: 40%;
  }
}
@media (max-width: 600px) {
  .searchContainer input {
    width: 50%;
  }
}
@media (max-width: 400px) {
  .searchContainer input {
    width: 60%;
  }
}

.notes-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  height: 80%;
  overflow-y: auto;
  overflow-x: hidden;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none;  /* IE 10+ */
  background-color:  #121212;
  padding: 0 2.5rem;
}
.notes-container::-webkit-scrollbar {
    width: 0px;
    background: transparent; 
}
/* .notes-container::-webkit-scrollbar { 
  display: none;
} */

.note {
  width: 23.5%;
  margin-bottom: 3rem;
  margin-right: 2%;
  padding: 1rem 2rem;
  text-align: left;
  background-color: #272727;
  min-height: 30rem; 
  cursor: pointer;
  border-radius: 2rem;
  border: none;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}
.note:hover {
  border: 1px solid red;
}
.note:nth-child(4n) {
  margin-right: 0;
  background: red;
}
.note:hover:nth-child(4n) {
  margin-right: 0;
  background: #272727;
}
@media (max-width: 1200px) {
  .note {
    width: 32%;
  }
  .note:nth-child(4n) {
    margin-right: 2rem;
    background: #272727;
  }
  .note:hover:nth-child(4n) {
    margin-right: 2rem;
    background: #272727;
  }
  .note:nth-child(3n) {
    margin-right: 0;
    background: red;
  }
  .note:hover:nth-child(3n) {
    margin-right: 0;
    background: #272727;
  }
}
@media (max-width: 800px) {
  .note {
    width: 48%;
  }
  .note:nth-child(odd) {
    margin-right: 2rem;
    background: #272727;
  }
  .note:hover:nth-child(odd) {
    margin-right: 2rem;
    background: #272727;
  }
  .note:nth-child(even) {
    margin-right: 0;
    background: red;
  }
  .note:hover:nth-child(even) {
    margin-right: 0;
    background: #272727;
  }
}

@media (max-width: 600px) {
  .note {
    width: 100%;
    background-color: #272727;
  }
  .note:nth-child(odd) {
    margin-right: 0;
    background: #272727;
  }
  .note:hover:nth-child(odd) {
    margin-right: 0;
    background: #272727;
  }
  .note:nth-child(even) {
    margin-right: 0;
    background: red;
  }
  .note:hover:nth-child(even) {
    margin-right: 0;
    background: #272727;
  }
}



.markdown-title {
  font-weight: bold;
  font-size: 2rem;
  border-bottom: 1px solid grey;
  padding-bottom: 0.5rem;
}

.note .markdown-title p{
  font-weight: bold;
  font-size: 2rem;
  color: #fbffff;
}

.note h3 {
  font-weight: bold;
  font-size: 2rem;
  color: #fbffff;
  border-bottom: 1px solid grey;
  padding-bottom: 0.5rem;
}

.markdown-content {
  font-size: 1.2rem;
  padding-top: 0.5rem;
}

.note .markdown-content p {
  font-size: 1.6rem;
  padding-top: 0.5rem;
  color: #fbffff;
}

.note p {
  font-size: 1.6rem;
  padding-top: 0.5rem;
  color: #fbffff;
  overflow: hidden;
}

.form {
  width: 100%;
  padding: 5rem 2.5rem;
  padding-top:120px;
  height: 100%;
  background-color: #121212;
}

.form h2 {
  font-size: 2rem;
  font-weight: bold;
  text-align: left;
  margin-bottom: 1rem;
  color: #fbffff;
}

.form-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  height: 100%;
}

.input-title {
  width: 100%;
  height: 5rem;
  border-radius: 0.3rem;
  margin-bottom: 1.6rem;
  padding-left: 1.5rem;
  font-family: Roboto, Arial, sans-serif;
  font-size: 1.6rem;
  line-height: 2.4rem;
}
.input-content {
  width: 100%;
  height: 100%;
  border-radius: 0.3rem;
  margin-bottom: 1.6rem;
  resize: none;
  padding: 1.5rem;
}

textarea {
  font-family: Roboto, Arial, sans-serif;
  font-size: 1.6rem;
  line-height: 2.4rem;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none;  /* IE 10+ */
}
textarea::-webkit-scrollbar {
    width: 0px;
    background: transparent; 
}

.form-button {
  width: 10rem;
  height: 5rem;
  cursor: pointer;
  margin-bottom: 1.25rem;
  background-color: #fbffff;
  border: none;
  color: black;
  font-size: 1.6rem;
  border-radius: 3rem;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}

.form-button:hover {
  background-color: red;
  color: #fbffff;
}

.delete-false {
  display: none;
}

.delete-true {
  position: fixed;
  top: 0px;
  left: 0px;
  background-color: rgba(216, 216, 216, 0.99);
  width: 100vw;
  height: 100vh;
}

.delete-container {
  margin: 0 auto;
  margin-top: 10rem;
  /* background-color: #272727; */
  /* border: 1px solid grey; */
  width: 50rem;
  height: 20rem;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}
@media (max-width: 600px) {
  .delete-container {
    width: 90%;
  }
}



.delete-container h3 {
  font-weight: bold;
  color: #272727;
}

.delete-btn-yes {
  width: 15rem;
  height: 5rem;
  cursor: pointer;
  margin-bottom: 1.25rem;
  background-color: rgb(202, 0, 26);
  border: 1px solid rgb(202, 0, 26);
  color:#fbffff;
  font-size: 1.6rem;
  border-radius: 0.3rem;
  margin-right: 2rem;
}

.delete-btn-no {
  width: 15rem;
  height: 5rem;
  cursor: pointer;
  margin-bottom: 1.25rem;
  background-color: #00e600;
  border: 1px solid #00e600;
  color: white;
  font-size: 1.6rem;
  border-radius: 0.3rem;
}

@media (max-width: 600px) {
  .delete-container h3 {
    padding: 0 2rem;
  }
  .delete-btn-yes {
    width: 40%;
  }
  .delete-btn-no {
    width: 40%;
  }
}

.note-wrapper {
  width: 100%;
  padding: 1rem 2.5rem;
  padding-top: 110px;
  height: 100%;
  background-color: #121212;
  display: flex;
  flex-direction: column;
  align-items: center;

}

.sub-nav {
  /* border: 1px solid red; */
  display: flex;
  justify-content: flex-end;
  width: 100%;
}

.sub-nav-links {
  width: 12rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.sub-nav-btn {
  cursor: pointer;
  background-color: transparent;
  border: none;
  text-decoration: none;
  height: 100%;
  color: #fbffff;
  font-size: 2rem;
  /* border: 1px solid red; */
}

.sub-nav-links-fix {
  height: 100%;
  vertical-align: center;
  /* padding-bottom: 0.5rem; */
}

.sub-nav-btn:hover {
  color: red;
}

.note-body {
  /* border:1px solid red; */
  width: 100%;
  margin-top: 1.8rem;
  height: calc(100%-180px);
  overflow-y: auto;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none;  /* IE 10+ */
}
.note-body::-webkit-scrollbar {
    width: 0px;
    background: transparent; 
}

.note-body h2 {
  font-size: 2rem;
  font-weight: bold;
  text-align: left;
  margin-bottom: 1rem;
  color:#fbffff;
}

.note-body p {
  font-size: 1.2rem;
  padding-top: 0.5rem;
  font-weight: 600;
  text-align: left;
  color:#fbffff;
}

.login-form {
  margin: 0 auto;
  width: 35%;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgb(211, 210, 211);
}

.login-form h3 {
  font-weight: bold;
  margin: 1rem 0;
}

.login-form input {
  width: 90%;
  margin-bottom: 1rem;
  height: 5rem;
  padding-left: 1.5rem;
}

.login-btn {
  width: 90%;
  height: 5rem;
  cursor: pointer;
  margin-bottom: 1.25rem;
  background-color: rgb(36, 184, 189);
  border: 1px solid rgb(36, 184, 189);
  color: white;
  font-size: 1.6rem;
  border-radius: 0.3rem;
}

.sort, .sort-markdown{
  margin: 1rem 0;
  display: flex;
  /* border: 1px solid red; */
  justify-content: center;
  /* border-radius: 0.5rem;
  border: none; */
  color: white;
  font-size: 1.6rem;
  padding: 0.5rem;
  
}

.sort select, .sort-markdown select {
  border-radius: 0.5rem;
  border: none;
  width: 15%;
  background-color: #272727;
  color: white;
  font-size: 1.6rem;
  padding: 0.5rem;
  cursor: pointer;
}
@media (max-width: 1200px) {
  .sort select {
    width: 25%;
  }
}
@media (max-width: 800px) {
  .sort select {
    width: 40%;
  }
}
@media (max-width: 600px) {
  .sort select {
    width: 50%;
  }
}
@media (max-width: 400px) {
  .sort select {
    width: 60%;
  }
}



#div1, #div2 {
  float: left;
  width: 200px;
  height: 100px;
  margin: 10px;
  padding: 10px;
  border: 1px solid black;
}
