/*------------------------------------*\
RESET
\*------------------------------------*/
/* http://meyerweb.com/eric/tools/css/reset/ 
v2.0b1 | 201101 
NOTE:WORK IN PROGRESS
USE WITH CAUTION AND TEST WITH ABANDON */

html,body,div,span,applet,object,iframe,
h1,h2,h3,h4,h5,h6,p,blockquote,pre,
a,abbr,acronym,address,big,cite,code,
del,dfn,em,img,ins,kbd,q,s,samp,
small,strike,strong,sub,sup,tt,var,
b,u,i,center,
dl,dt,dd,ol,ul,li,
fieldset,form,label,legend,
table,caption,tbody,tfoot,thead,tr,th,td,
article,aside,canvas,details,figcaption,figure,
footer,header,hgroup,menu,nav,section,summary,
time,mark,audio,video{
  margin:0;
  padding:0;
  border:0;
  outline:0;
  font-size:100%;
  font:inherit;
  vertical-align:baseline;
}
/* HTML5 display-role reset for older browsers */
article,aside,details,figcaption,figure,
footer,header,hgroup,menu,nav,section{
  display:block;
}
body{
  line-height:1;
}
ol,ul{
  list-style:none;
}
blockquote,q{
  quotes:none;
}
blockquote:before,blockquote:after,
q:before,q:after{
  content:’’;
  content:none;
}
ins{
  text-decoration:none;
}
del{
  text-decoration:line-through;
}

table{
  border-collapse:collapse;
  border-spacing:0;
}

/* Your Code Goes Here */

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
}

body {
  font-size: 1.6rem;
  background-color: #FFFFFF;
  color: #222222;
  font-family: Roboto,Arial,sans-serif;
  font-size: 1.6rem;
  line-height: 2.4rem;
  margin: 0 auto;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
